// Generated with util/create-component.js
import React from 'react'
import './dragAndDropFile.scss'
import { FileUploader } from 'react-drag-drop-files'
export interface DragAndDropFileProps {
  handleChange: (file: File | FileList) => void
  onDrop?: () => void
  multiple?: boolean
  name: string
  types: string[]
  disabled?: boolean
  onSelect?: (file: File) => void
  renderFile: (files: any) => any
  files: any[]
}
const DragAndDropFile = ({
  handleChange,
  name,
  types,
  disabled,
  onDrop,
  multiple = false,
  onSelect,
  files,
  renderFile,
}: DragAndDropFileProps) => (
  <>
    <FileUploader
      handleChange={handleChange}
      name={name}
      multiple={multiple}
      types={types}
      disabled={disabled}
      onDrop={onDrop}
      onSelect={onSelect}
    />
    {files.map((file, index) => {
      return <div key={index}>{renderFile(file)}</div>
    })}
  </>
)
export default DragAndDropFile
