import React, { useEffect, useMemo, useState } from 'react'
import { useTable, useSortBy, usePagination } from 'react-table'
import { Panel, PanelHeader, PanelBody } from '../Panel/Panel'
import Pagination from './Pagination'
import { useHorizontalScroll } from '../../Hooks/useHorizontalScroll'
import { formatCell } from '../../Util/formatting'
import DataTable from './DataTable/DataTable'
import styles from '../../styles.module.scss'
import PageSelect from './PageSelect'
import { Collapse } from 'react-collapse'
const useTableAny = useTable as any
const TableData = React.forwardRef(
  (
    {
      columnData,
      cellData,
      fetchData,
      loading,
      pageCount: controlledPageCount,
      theme,
      noHeader,
      totalRecords = 0,
      actionClick = () => {},
      idName = '',
      rootStyles = {},
      IconComponent = null,
      filesEndpoint = '',
      scopedSlots,
      paginationPosition = 'bottom-right',
      defaultPageSize = 25,
      actionConditions,
      showAdvancedSearch,
    }: any,
    ref,
  ) => {
    const scrollRef = useHorizontalScroll()
    const [isOpenAdvanced, setIsOpenAdvanced] = useState(false)
    const columns = useMemo(
      () =>
        columnData.map(
          ({
            label,
            name,
            alignment,
            type = 'text',
            customWidth,
            actions,
            sortable = true,
            hidePeriod = false,
            rowClickFunc,
          }: any) => ({
            Header: label,
            accessor: name,
            align:
              ['number', 'currency'].includes(type) && !alignment ? 'right' : alignment || 'left',
            type,
            customWidth,
            actions,
            sortable,
            rowClickFunc,
            Cell: ({ row, value }: any) => {
              const params = {
                value,
                type,
                hidePeriod,
                actions,
                alignment,
                actionClick,
                row,
                idName,
                IconComponent: type === 'icon' ? IconComponent : null,
                filesEndpoint,
                actionConditions,
              }
              return scopedSlots?.[name] ? scopedSlots[name](params) : formatCell(params)
            },
          }),
        ),
      [],
    )
    //Removed useMemo in favor of table updating when passed in cellData changes...
    //empty dep array in useMemo was preventing the update
    // const data = useMemo(() => cellData, [])
    const data = cellData

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, sortBy },
    }: any = useTableAny(
      // TODO:JAVASCRIPT (pageIndex: 0) TYPESCRIPT (here => hiddenColumns: [])
      {
        columns,
        data,
        autoResetPage: false,
        initialState: {
          pageIndex: 0,
          pageSize: [10, 25, 50].includes(defaultPageSize) ? defaultPageSize : 25,
        },
        manualPagination: true,
        pageCount: controlledPageCount,
        manualSortBy: true,
      },
      useSortBy,
      usePagination,
    )
    React.useImperativeHandle(ref, () => ({
      fetchNewData() {
        const searchString = searchInputRef ? searchInputRef.current.value : null
        fetchData({
          pageIndex,
          pageSize,
          searchString,
          sortBy,
        })
      },
    }))

    const searchInputRef = React.useRef<any>('')
    const fetchWithSearchString = () => {
      const searchString = searchInputRef ? searchInputRef.current.value : null
      console.log(searchString, 'Search String')
      fetchData({
        pageIndex,
        pageSize,
        searchString,
        sortBy,
        resetPage: { handler: gotoPage, page: 0 },
      })
    }

    useEffect(() => {
      const searchString = searchInputRef ? searchInputRef.current.value : null
      fetchData({ pageIndex, pageSize, searchString, sortBy })
    }, [fetchData, pageIndex, pageSize, sortBy])

    const handleKeypress = (e: any) => {
      //it triggers by pressing the enter key
      if (e.key === 'Enter') {
        console.log(searchInputRef);
        
        fetchWithSearchString()
      }
    }

    const pageSelectProps = {
      canPreviousPage,
      gotoPage,
      previousPage,
      pageIndex,
      pageOptions,
      canNextPage,
      nextPage,
      pageCount,
    }
    console.log({ columns })
    return (
      <div style={rootStyles}>
        <Panel className={theme === 'dark' ? styles['c-dark-theme'] : ''}>
          {!noHeader && <PanelHeader>SS React DataTable</PanelHeader>}

          <div
            className={`table-search-and-pagination ${styles['text-light']} ${styles['mb-sm-1']}`}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <div
              className={`table-search ${styles['mr-xs-2']} ${styles['mb-xs-1']} ${styles['mb-sm-0']}`}
              style={{ display: 'flex' }}
            >
              <input
                className={`${styles['form-control']}`}
                type='text'
                style={{
                  borderRadius: 0,
                  color: theme === 'dark' ? '#fff' : '#000',
                  width: '25rem',
                }}
                placeholder='Search...'
                ref={searchInputRef}
                onKeyPress={handleKeypress}
              />
              <button
                className={`${styles['search-button']} ${styles['d-xs-block']} ${styles['d-sm-none']}`}
                onClick={fetchWithSearchString}
              >
                Search
              </button>
              {showAdvancedSearch && (
                <button
                  className={`${styles.btn} ${styles[`btn-info`]} ${styles['btn-sm']}`}
                  style={{ marginLeft: '0.5rem' }}
                  onClick={() => setIsOpenAdvanced(!isOpenAdvanced)}
                >
                  Advanced
                </button>
              )}
            </div>

            <div
              className={`table-pagination ${styles['ml-sm-auto']}`}
              style={{
                fontSize: 12,
              }}
            >
              <div
                className={
                  paginationPosition === 'top-right'
                    ? `${styles['d-xs-flex']} ${styles['justify-content-xs-end']} ${styles['mb-xs-2']}`
                    : ''
                }
                style={{
                  color: theme === 'dark' ? '#fff' : '#000',
                }}
              >
                <span style={{ paddingRight: '0.5rem' }}>Page Size:</span>
                {[10, 25, 50].map((size) => (
                  <span
                    className='table-pagination-page'
                    key={size}
                    style={{
                      fontWeight: pageSize === size ? 'bold' : 'normal',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setPageSize(size)
                    }}
                  >
                    {size}
                    {size === 50 ? (
                      ''
                    ) : (
                      <span
                        className={`${styles['text-muted']}`}
                        style={{
                          fontWeight: 'normal',
                          padding: '0 0.25rem',
                        }}
                      >
                        |
                      </span>
                    )}
                  </span>
                ))}
              </div>
              {paginationPosition === 'top-right' ? <PageSelect {...pageSelectProps} /> : null}
            </div>
          </div>
          <Collapse isOpened={isOpenAdvanced}>
            <div
              style={{
                color: '#000',
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '0.5rem',
                padding: '1rem',
              }}
              className={`table-search ${styles['mr-xs-2']} ${styles['mb-xs-1']} ${styles['mb-sm-0']}`}
            >
              {columns.map((column: any, index) => {
                if (column.Header !== '') {
                  return (
                    <div key={index}>
                      <label>
                        {column.Header}
                        <input
                          className={`${styles['form-control']}`}
                          type='text'
                          style={{
                            color: theme === 'dark' ? '#fff' : '#000',
                            maxWidth: '10rem',
                          }}
                          placeholder={`${column.Header}`}
                          // ref={searchInputRef}
                          onKeyPress={handleKeypress}
                        />
                      </label>
                    </div>
                  )
                }
              })}
            </div>
          </Collapse>
          <DataTable
            className={theme === 'dark' ? styles['c-dark-theme'] : ' '}
            scrollRef={scrollRef}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            loading={loading}
            totalRecords={totalRecords}
            controlledPageCount={controlledPageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            actionClick={actionClick}
            idName={idName}
          />
          <hr className={styles['m-xs-0']} style={{ height: 1 }} />
          <PanelBody>
            <Pagination
              {...pageSelectProps}
              paginationPosition={paginationPosition}
              setPageSize={setPageSize}
              pageSize={pageSize}
              loading={loading}
              page={page}
              totalRecords={totalRecords}
            />
          </PanelBody>
        </Panel>
      </div>
    )
  },
)

export default TableData
