import React from 'react'
import MaskedInput from 'react-text-mask'

const PhoneNumberInput: React.FC<any> = ({
  disabled = false,
  className = '',
  inputStyles = {},
  name = '',
  value = '',
  onChange,
  showButton = false,
  verifyFunc,
  ButtonComponent,
}) => {
  return (
    <>
      <MaskedInput
        disabled={disabled}
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        className={className}
        style={inputStyles}
        name={name}
        value={value}
        type='text'
        placeholderChar='#'
        guide
        showMask
        onChange={onChange}
      />
      {showButton ? (
        ButtonComponent ? (
          ButtonComponent()
        ) : (
          <small className='input-field-button' onClick={verifyFunc}>
            Verify
          </small>
        )
      ) : null}
    </>
  )
}

export { PhoneNumberInput }
