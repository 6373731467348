import { format, isThisWeek, isThisYear, isToday, isYesterday } from 'date-fns'
import React from 'react'
import Avatar from './Avatar'

export const Note = ({notes}) => {
  
  const forHumansDate = (date: string, showHour = false) => {
    if (date) {
      const dateParse = new Date(date)
      if (showHour) {
        return `${format(dateParse, "hh:mm aaaaa'm'")}`
      }
      if (isToday(dateParse)) {
        return `Today`
        // return `Today ${format(dateParse, "hh:mm aaaaa'm'")}`
      }
      if (isYesterday(dateParse)) {
        return `Yesterday`
        // return `Yesterday ${format(dateParse, "hh:mm aaaaa'm'")}`
      }

      if (isThisWeek(dateParse)) {
        return format(dateParse, 'EEEE')
      }
      if (isThisYear(dateParse)) {
        return format(dateParse, 'EEEE, LLL d, yyyy')
      }
      return `${format(dateParse, 'EEEE, LLL d, yyyy')}`
    }
    return ''
  }
  
  return (
    <div
      style={{
        backgroundColor: '#0000',
        padding: '5px',
      }}
    >
    <div
      style={{
        width: '100%',
        height: '1px',
        backgroundColor: '#D9D9D9',
        marginLeft: '5px',
        marginTop: '10px',
        marginBottom: '0.25rem',
      }}
    ></div>
    {notes.map((n, i) => {
      return (
        <div key={n.Id}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: 'white',
          }}
        >
          { ((i>0 && n.ChangedOn.substring(0,10)!==notes[i-1].ChangedOn.substring(0,10)) || i===0 ) 
          && (
            <span
              style={{
                color: '#111',
                fontSize: '12px',
                backgroundColor: '#FBFBFB',
                padding: '5px',
                margin: '0 auto',
                marginBottom: '1rem',
                borderRadius: '10px',
              }}
            >
              {forHumansDate(n.ChangedOn)}
            </span>
          )}
          <div
            key={n.Id}
            style={{
              width: '100%',
              border: 'solid #eee 1px',
              textAlign: 'center',
              marginBottom: '10px',
              marginTop: '2px',
              padding: '10px',
              borderRadius: '10px',
              backgroundColor: '#FBFBFB',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ color: '#000', whiteSpace: 'nowrap' }}> {n.UserName}</span>
              <span style={{ color: '#000', whiteSpace: 'nowrap' }}>
                {forHumansDate(n.ChangedOn, true)}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'flex',
                }}
              >
                <span style={{ border: 'solid 1px #ddd', borderRadius: '100%' }}>
                  <Avatar name={n.UserName} FilesId_Avatar={0}></Avatar>
                </span>
                <span
                  style={{
                    color: 'black',
                    marginLeft: '1rem',
                    alignSelf: 'center',
                    fontSize: '1rem',
                  }}
                >
                  {n.Notes}
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    })}
  </div>
          
  )
}
