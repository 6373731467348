import React from 'react'
import styles from '../../styles.module.scss'

interface PropsCustomCheckBox {
  value: any
}
const CustomCheckbox = ({ value = false }: PropsCustomCheckBox) => {
  return value ? (
    <div className={`${styles.checkbox} ${styles['checkbox-css']}`}>
      <input
        type='checkbox'
        id='cssCheckbox1'
        checked={value}
        // defaultChecked={value}
        // checked={value}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
        }}
        readOnly
      />
      <label htmlFor='cssCheckbox1'></label>
    </div>
  ) : null
}

export default CustomCheckbox
