// Generated with util/create-component.js
import React from 'react'
import {
  TextInput,
} from './Components'
import './InputField.scss'

const InputTypes = {
  text: TextInput,
  notes: TextInput,
  textarea: TextInput,
}

const InputField: React.FC<any> = ({
  type = 'text',
  name = '',
  label = '',
  value = '',
  className = '',
  rootClassName = '',
  disabled = false,
  onChange = () => console.warn('NO onChange Handler Detected'),
  showButton = false,
  showStrength = false,
  verifyFunc = () => console.warn('NO verifyFunc Detected!'),
  alignment = 'left',
  maxLength = 0,
  decimals = 0,
  placeholder = '',
  showSeparator = false,
  inputStyles = {},
  labelStyles = {},
  rootStyles: customRootStyles = {},
  forCombo = false,
  params = { inputProps: {}, InputProps: {} },
  innerRef,
  IconComponent,
  ButtonComponent,
  children,
  mask,
  maskCharacter,
  autoComplete = 'new-password',
  textAreaProps = {},
  size = 'sm',
  renderModal,
}) => {
  const { ref, ...otherInputProps } = params.inputProps
  const inputRef = ref ? ref : React.useRef(null)
  const InputType = InputTypes[type]

  const {
    rootStyles = {},
    styles = {},
    labelStylesObj = {},
  } = makeStyles(type, label, disabled, inputStyles, labelStyles)

  const handleChange = ({ target }) => {
    onChange({ name: target.name, value: target.value })
  }

  return (
    <div
      ref={params.InputProps.ref}
      className={`${rootClassName}${disabled ? ' disabled ' : ''}`}
      style={{ ...rootStyles, ...customRootStyles }}
    >
      {label ? (
        <label htmlFor={name} style={labelStylesObj}>
          {label}
        </label>
      ) : null}
      {params.InputProps.startAdornment ? params.InputProps.startAdornment : null}
      {children ? (
        children
      ) : (
        <InputType
          name={name}
          label={label}
          value={value}
          type={type}
          disabled={disabled}
          className={className}
          onChange={handleChange}
          showButton={showButton}
          showStrength={showStrength}
          verifyFunc={verifyFunc}
          alignment={alignment}
          maxLength={maxLength}
          decimals={decimals}
          showSeparator={showSeparator}
          inputStyles={styles}
          placeholder={placeholder}
          forCombo={forCombo}
          comboProps={forCombo ? { innerRef: inputRef, otherInputProps: otherInputProps } : {}}
          innerRef={innerRef}
          IconComponent={IconComponent}
          ButtonComponent={ButtonComponent}
          mask={mask}
          maskCharacter={maskCharacter}
          autoComplete={autoComplete}
          textAreaProps={textAreaProps}
          size={size}
          renderModal={renderModal}
        />
      )}

      {params.InputProps.endAdornment ? params.InputProps.endAdornment : null}
    </div>
  )
}

export default InputField

const makeStyles = (
  type: string,
  label: string,
  disabled: boolean,
  customInputStyles: any,
  customLabelStyles: any,
) => {
  return {
    rootStyles:
      type === 'fancyswitch'
        ? {
            display: 'flex',
            padding: '0.47rem 0.25rem',
            alignItems: 'center',
            justifyContent: 'space-between',
          }
        : {
            padding: label ? (type === 'textarea' ? '15px 5px 5px' : '15px 5px 1px') : 0,
            position: 'relative' as 'relative',
            display: 'flex',
            flexWrap: 'wrap' as 'wrap',
          },
    styles: {
      border: 'none',
      outline: 'none',
      width: '100%',
      cursor: disabled ? 'not-allowed' : 'inherit',
      ...customInputStyles,
    },
    labelStylesObj: {
      fontSize: 10,
      position: type === 'fancyswitch' ? 'initial' : 'absolute', //since we move the label down when using a switch input
      left: 7.5,
      top: 2,
      transform: 'translateY(0)',
      transition: 'all 200ms',
      fontWeight: 'bold',
      userSelect: 'none',
      ...customLabelStyles,
    },
  }
}
