
export const getInitialsFromName = (string: string) => {
  if (string) {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
  }
  return ''
}


export const validate = (condition, messageError) => {
  if (condition) {
    throw new Error(messageError)
  }
}