import React from 'react'
// import PasswordStrengthBar from 'react-password-strength-bar';

const PasswordInput: React.FC<any> = ({
  disabled = false,
  className = '',
  inputStyles = {},
  name = '',
  value = '',
  onChange,
  showButton = false,
}) => {
  const [passwordShown, setPasswordShown] = React.useState(false)

  const togglePassword = () => {
    setPasswordShown((st) => !st)
  }

  return (
    <>
    <div>
      <input
        disabled={disabled}
        name={name}
        style={inputStyles}
        type={passwordShown ? 'text' : 'password'}
        autoComplete='new-password'
        value={value}
        className={className}
        onChange={onChange}
      />
      {showButton ? (
        <small className='input-field-button' onClick={togglePassword}>
          {passwordShown ? 'Hide' : 'Show'}
        </small>
      ) : null}

    </div>
    {/* <div>
      <PasswordStrengthBar password={value} />
    </div> */}
    </>
  )
}

export { PasswordInput }
