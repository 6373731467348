// Generated with util/create-component.js
import React from 'react'

import { CheckBoxGroupProps, MultiOption } from './CheckBoxGroup.types'

import './CheckBoxGroup.scss'
import InputField from '../InputField'
import CustomCheckbox from './CustomCheckbox'

const CheckBoxGroup: React.FC<CheckBoxGroupProps> = ({
  name = '',
  label = '',
  options,
  onChangeCheckBox = ({ name, value }) => {
    console.log('NO CHANGE HANDLER DETECTED FOR CHECKBOX', { name, value })
  },
  customClassNames = { root: '', input: '' },
}) => {
  const itemRef = React.useRef<any>()
  const isMulti =
    options.length &&
    (options[0] as MultiOption).options &&
    (options[0] as MultiOption).options.length > 0
  return (
    <div data-testid='CheckBoxGroup' className='InputField-CheckBoxGroup'>
      <InputField
        rootClassName={customClassNames && customClassNames.root ? customClassNames.root : ''}
        type='checkboxgroup'
        name={name}
        label={label}
      >
        <div
          ref={itemRef}
          className={customClassNames && customClassNames.input ? customClassNames.input : ''}
        >
          {isMulti
            ? (options[0] as MultiOption).options.map(({ label }, idx: number) => (
                <span
                  key={idx}
                  style={{
                    fontSize: 12,
                    transition: 'all 200ms',
                    fontWeight: 'bold',
                    userSelect: 'none',
                    marginLeft: idx > 0 ? '1rem' : 0,
                  }}
                >
                  {label}
                </span>
              ))
            : null}
          {options.length ? (
            options.map((option: any, idx: number) => (
              <CustomCheckbox
                key={idx}
                id={idx}
                onChange={onChangeCheckBox}
                option={option}
                isMulti={isMulti}
              />
            ))
          ) : (
            <span
              style={{
                fontSize: 12,
                transition: 'all 200ms',
                fontWeight: 'bold',
                userSelect: 'none',
              }}
            >
              No Options
            </span>
          )}
        </div>
      </InputField>
    </div>
  )
}

export default CheckBoxGroup
