import React from 'react'
import styles from '../../styles.module.scss'

const PageSelect: React.FC<any> = ({
  canPreviousPage,
  gotoPage,
  previousPage,
  pageIndex,
  pageOptions,
  canNextPage,
  nextPage,
  pageCount,
}) => {
  return (
    <ul className={`${styles.pagination} ${styles['mb-xs-0']}`}>
      <li className={styles['page-item']}>
        <button
          className={styles['page-link']}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {/* /* <i className={`${styles.fa} ${styles['fa-angle-double-left']}`}></i> */}
          first
        </button>
      </li>
      <li className={styles['page-item']}>
        <button
          className={styles['page-link']}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {/* <i className={`${styles.fa} ${styles['fa-angle-left']}`}></i> */}
          prev
        </button>
      </li>
      <li
        className={`${styles['page-item']} ${styles['d-xs-flex']} ${styles['align-items-xs-center']} ${styles['px-xs-2']}`}
      >
        <small style={{ marginTop: '0.25rem' }}>
          {/*  <span
            className={`${styles['d-sm-inline-block']} ${styles['d-xs-none']} ${styles['pr-xs-1']}`}
          >
            Page{' '}
          </span> */}
          <strong>
            {/*  <input
              className={styles['form-control']}
              type='number'
              value={pageIndex + 1}
              style={{ width: 40, height: 25, textAlign: 'center', outline: 'none' }}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
            /> */}
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </small>
      </li>
      <li className={styles['page-item']}>
        <button className={styles['page-link']} onClick={() => nextPage()} disabled={!canNextPage}>
          {/* <i className={`${styles.fa} ${styles['fa-angle-right']}`}></i> */}
          next
        </button>
      </li>
      <li className={styles['page-item']}>
        <button
          className={styles['page-link']}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {/* <i className={`${styles.fa} ${styles['fa-angle-double-right']}`}></i> */}
          last
        </button>
      </li>
    </ul>
  )
}

export default PageSelect
