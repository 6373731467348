import React from 'react'

const TextInput: React.FC<any> = ({
  disabled = false,
  className = '',
  inputStyles = {},
  name = '',
  type = 'text',
  value = '',
  onChange,
  forCombo = false,
  comboProps = {},
  showButton = false,
  ButtonComponent,
  placeholder = '',
  innerRef, //From Notes
  autoComplete,
  textAreaProps = {},
}) => {
  const { innerRef: ref, otherInputProps } = comboProps
  const extraProps = forCombo ? { ref, ...otherInputProps } : {}
  const baseProps = {
    disabled,
    name,
    style: inputStyles,
    className,
    value,
    onChange,
    placeholder,
    autoComplete,
    ...textAreaProps,
    ref: innerRef, //For notes, when combobox props dont get applied, when combobox props get applied, this ref is overridden
    ...extraProps,
  }
  const { value: controlledVal, onChange: onChangeFunc, ...rest } = baseProps
  const finalProps = innerRef && type === 'notes' ? rest : baseProps

  const inputComponent = ['textarea', 'notes'].includes(type) ? (
    <textarea {...finalProps} />
  ) : (
    <input type={type} {...finalProps} />
  )
  return (
    <>
      {inputComponent}
      {showButton ? (
        ButtonComponent ? (
          ButtonComponent()
        ) : (
          <small className='input-field-button'>Button</small>
        )
      ) : null}
    </>
  )
}

export { TextInput }
