import axios from 'axios'
import React from 'react'
/* import styles from './styles.module.css' */
import Table from './Components/Table/Table'
// import makeData from './make-data'
// import { BrowserRouter, Route } from 'react-router-dom'
// import 'bootstrap/dist/css/bootstrap.css'
// import '@fortawesome/fontawesome-free/css/all.min.css'
// import '@ugikie/ss-react-datatable-styles/index.css'
// import '@ugikie/ss-react-datatable-styles/scss/react.scss'

// const getDataLength = async (
//   endpoint: string,
//   modelName: string,
//   searchString = null,
//   queryParams = {},
//   apiKey = '',
// ) => {
//   const request = { SearchString: searchString, ...queryParams }
//   const res = await fetch(`${endpoint}/List/${modelName}`, {
//     method: 'POST',
//     body: JSON.stringify(request),
//     headers: { Authorization: `Bearer ${apiKey}`, 'Content-Type': 'application/json' },
//   })
//   const { data } = await res.json()
//   return data.length
// }
interface QueryParams {
  Name: string
  Value: string
}
export interface ColumnData {
  label?: any
  name?: any
  alignment?: 'right' | 'left' | 'center'
  type?:
    | 'text'
    | 'number'
    | 'currency'
    | 'phone'
    | 'date'
    | 'time'
    | 'datetime'
    | 'boolYesOrNo'
    | 'boolTrueOrFalse'
    | 'boolCheckbox'
    | 'action'
    | 'picture'
    | 'color'
    | 'icon'
  customWidth?: any
  actions?: any
  sortable?: boolean
  hidePeriod?: boolean
  editType?: any
  row?: any
  rowClickFunc?: any
}
interface PropsDataTable {
  columnData?: ColumnData[] | any
  theme?: string
  noHeader?: boolean
  // cellData = [],
  endpoint?: string
  modelName?: string
  idName?: string
  queryParams?: QueryParams[]
  actionClick?: any
  rootStyles?: React.CSSProperties
  IconComponent?: any
  parseBools?: boolean
  filesEndpoint?: string
  apiKey?: string
  scopedSlots?: any
  paginationPosition?: 'top-right' | 'bottom-right'
  defaultPageSize?: number
  actionConditions?: Object
  extension?: string
  showAdvancedSearch?: boolean
}

const DataTable = React.forwardRef<any, PropsDataTable>(
  (
    {
      columnData = [],
      theme = 'light',
      noHeader = false,
      // cellData = [],
      extension,
      endpoint = 'https://dispatch.softsmartinc.com/Services/Models/List',
      modelName = 'Apps',
      idName = 'Id',
      queryParams = [],
      actionClick = () => {},
      rootStyles = {},
      IconComponent = null,
      parseBools = false,
      filesEndpoint = '',
      apiKey = '',
      scopedSlots = {},
      paginationPosition = 'bottom-right',
      defaultPageSize = 25,
      actionConditions = {},
      showAdvancedSearch = false,
    }: any,
    ref,
  ) => {
    const [listData, setListData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [pageCount, setPageCount] = React.useState(0)
    const fetchIdRef = React.useRef(0)
    const totalRecordsRef = React.useRef(0)

    const fetchData = React.useCallback(
      async ({ pageSize, pageIndex, searchString = null, resetPage = false, sortBy = [] }) => {
        try {
          // This will get called when the table needs new data
          resetPage && resetPage.handler && resetPage.handler(resetPage.page)
          // Give this fetch an ID
          const fetchId = ++fetchIdRef.current
          // Set the loading state
          setLoading(true)

          /* const request: any = {
          Page: pageIndex + 1,
          PageSize: pageSize,
          SearchString: searchString ? searchString : null,
          ...(queryParams ?? {}),
        } */
          let sortByRequest = {
            Name: 'OrderBy',
            Value: null,
          }
          let sortByDirRequest = {
            Name: 'OrderByDir',
            Value: null,
          }

          if (sortBy.length) {
            sortByRequest = {
              Name: 'OrderBy',
              Value: sortBy[0].id,
            }
            sortByDirRequest = {
              Name: 'OrderByDir',
              Value: sortBy[0].desc ? 'ASC' : 'DESC',
            }
          }
          const requestArray = [
            { ...sortByRequest },
            { ...sortByDirRequest },
            {
              Name: 'SearchString',
              Value: searchString ? searchString : null,
            },
            {
              Name: 'Page',
              Value: pageIndex + 1,
            },
            {
              Name: 'PageSize',
              Value: pageSize,
            },
            ...(queryParams ?? {}),
          ]

          /* if (sortBy.length) {
          request.OrderBy = sortBy[0].id
          request.OrderByDir = sortBy[0].desc ? 'DESC' : 'ASC'
        } */

          const { data } = await axios.post(
            endpoint,
            {
              Model: modelName,
              Extension: extension,
              Params: requestArray,
            },
            {
              headers: { Authorization: `Bearer ${apiKey}`, 'Content-Type': 'application/json' },
            },
          )
          // console.log('dataList', data);
          

          // Only update the data if this is the latest fetch
          if (fetchId === fetchIdRef.current) {
            totalRecordsRef.current = data?.data?.ListInfo[0]?.RecordCount ?? 0
            parseBools && parseBooleans(data?.data.ListInfo ?? [])
            console.log('listInfo', data?.data.ListInfo);
            
            setListData(data?.data.ListInfo ?? [])
            setPageCount(Math.ceil(totalRecordsRef.current / pageSize))
            setLoading(false)
          }
        } catch (error) {
          console.log(error)
          setLoading(false)
          throw new Error(error.message)
        }
      },
      [],
    )

    return (
      <Table
        columnData={columnData}
        cellData={listData}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        theme={theme}
        ref={ref}
        noHeader={noHeader}
        totalRecords={totalRecordsRef.current}
        actionClick={actionClick}
        idName={idName}
        rootStyles={rootStyles}
        IconComponent={IconComponent}
        filesEndpoint={filesEndpoint}
        scopedSlots={scopedSlots}
        paginationPosition={paginationPosition}
        defaultPageSize={defaultPageSize}
        actionConditions={actionConditions}
        showAdvancedSearch={showAdvancedSearch}
      />
    )
  },
)

// const widths = {
//   width_xs: '150px',
//   width_sm: '300px',
//   width_md: '450px',
//   width_lg: '600px',
//   width_full: '100%',
//   width_0: '0px',
//   width_10: '10px',
//   width_20: '20px',
//   width_30: '30px',
//   width_40: '40px',
//   width_50: '50px',
//   width_60: '60px',
//   width_70: '70px',
//   width_80: '80px',
//   width_90: '90px',
//   width_100: '100px',
//   width_150: '150px',
//   width_200: '200px',
//   width_250: '250px',
//   width_300: '300px',
//   width_350: '350px',
//   width_400: '400px',
//   width_450: '450px',
//   width_500: '500px',
//   width_550: '550px',
//   width_600: '600px',
// }

const parseBooleans = (arr: any) => {
  let finalArr = arr
  arr.map((data: any, idx: any) => {
    Object.keys(data).map((d) => {
      if (data[d] === 'True') {
        finalArr[idx][d] = true
      } else if (data[d] === 'False') {
        finalArr[idx][d] = false
      }
      return d
    })
    return data
  })
  return finalArr
}

export default DataTable
