import React from 'react'

const IconList = ({ defaultIcons, setShowIcons, onChange, name }) => {
  return (
    <div className='icons'>
      {defaultIcons.map((icon: string) => (
        <div
          key={icon}
          title={icon}
          onClick={() => {
            onChange({ target: { name, value: icon } })
            setShowIcons(false)
            // toggleIconPicker();
          }}
          className='icon-li'
        >
          <i className={icon} style={{ color: '#0000' }}></i>
        </div>
      ))}
    </div>
  )
}
function areEqual(prevProps: any, nextProps: any) {
  return (
    prevProps.defaultIcons === nextProps.defaultIcons &&
    prevProps.setShowIcons === nextProps.setShowIcons
  )
}
export default React.memo(IconList, areEqual)
