// Generated with util/create-component.js
import React, { useState, useEffect } from 'react'

import { NotesProps, Note } from './Notes.types'

import './Notes.scss'
import axios from 'axios'
import InputField from '../InputField'
import SendIcon from './SendIcon'
interface EndPoint {
  Fetch: string //default Value
  Save: string //default Value
}
interface ClassNames {
  input?: any
  root?: any
}
interface Data {
  Fetch: any
  Save: any
}
interface PropsNotes {
  name?: string
  placeholder?: string
  classNames?: ClassNames
  renderNotes?: any
  Endpoints?: EndPoint
  authToken?: string
  data: Data
  metasId: string
}
const Notes: React.FC<PropsNotes> = ({
  name = 'Notes',
  placeholder = '',
  classNames = { input: '', root: '' },
  renderNotes,
  Endpoints = {
    Fetch: 'https://services.vitalcard.com:8118/Widgets', //default Value
    Save: 'https://services.vitalcard.com:8118/Widgets', //default Value
  },
  data: dataRequest,
  metasId,
  authToken = '',
}) => {
  const [notes, setNotes] = useState([])
  const inputRef = React.useRef<any>('')

  const fetchNotes = async () => {
    const { data } = await axios.post(
      `${Endpoints.Fetch}`,
      {
        ...dataRequest.Fetch,
      },
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    )
    data.data && setNotes(data.data)
  }

  useEffect(() => {
    fetchNotes()
  }, [])

  console.log(renderNotes);
  

  const addNote = async () => {
    const savedVal = inputRef.current.value
    inputRef.current.value = ''
    await axios.patch(
      `${Endpoints.Save}`,
      {
        ...dataRequest.Save,
        Params: [
          ...dataRequest.Save.Params,
          { Name: 'Notes', Value: savedVal },
          { Name: 'MetasId', Value: metasId },
        ],
      },
      { headers: { Authorization: `Bearer ${authToken}` } },
    )
    fetchNotes()
  }

  const renderAllNotes = renderNotes
    ? renderNotes
    : (notes: any) => notes.map(({ NotesId, Notes }) => <div key={NotesId}>{Notes}</div>)

  return (
    <div className='Notes-root'>
      <div style={{ width: '100%', display: 'inline-block', position: 'relative' }}>
        <InputField
          innerRef={inputRef}
          placeholder={placeholder}
          className={classNames.input + ' Notes-notes-area '}
          name={name}
          id={name}
          type='notes'
          rootClassName={classNames.root}
        />
        <SendButton addNote={addNote} />
      </div>

      {notes.length ? renderAllNotes(notes) : <h5 className='text-center mt-4'>No Notes Found</h5>}
    </div>
  )
}

export default Notes

const SendButton = ({ addNote }) => (
  <button className='Notes-send-button' onClick={addNote}>
    <SendIcon />
  </button>
)
