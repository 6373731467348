// Generated with util/create-component.js
import React from 'react'

export const DatePicker: React.FC<any> = ({
  disabled = false,
  className = '',
  inputStyles = {},
  name = '',
  value = '',
  onChange,
}) => (
  <input
    name={name}
    value={value}
    onChange={onChange}
    disabled={disabled}
    style={{
      width: '100%',
      border: 'none',
      ...inputStyles,
    }}
    className={`${className} input-field-date`}
    type='date'
  />
)
