import React from 'react'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { ComboBoxProps } from './ComboBox.types'
import InputField from '../InputField'
import './ComboBox.scss'

const ComboBox: React.FC<ComboBoxProps> = ({
  data,
  classNames,
  customClassNames,
  optionLabel,
  renderOption,
  value = null,
  handleChange,
  name = 'Combo',
  label = 'Combo',
  disabled = false,
  multiple = false,
  placeholder = 'Select an item',
  freeSolo = false,
  inputStyles = {},
  rootStyles = {},
  labelStyles = {},
}) => {
  const loading = data ? data.length === 0 : true

  const updateValue = (e: any, newValue: any) => {
    typeof handleChange === 'function' && handleChange(e, newValue)
  }

  const size: any = multiple ? 'small' : 'medium'

  const baseProps: any = {
    value,
    disabled,
    multiple,
    clearOnBlur: true,
    freeSolo,
    filterSelectedOptions: multiple,
    size,
    onChange: updateValue,
    openOnFocus: true,
    loading,
  }

  if (freeSolo) {
    //! Do not put this on in any other case or else the dropdown will remain open, only needs to be disabled for freeSolo.
    baseProps.open = false
  }

  const inputStyle = multiple ? { minWidth: 30, width: 0 } : {}
  const rootStyle = multiple ? { paddingRight: 50 } : {}
  return (
    <Autocomplete
      {...baseProps}
      id={name}
      options={data ? data : []}
      getOptionLabel={
        optionLabel
          ? optionLabel
          : (option: any) => (option.Description ? option.Description : option)
      }
      getOptionSelected={(option: any, value) =>
        option.CodeId && value.CodeId ? option.CodeId === value.CodeId : option === value
      }
      classes={
        classNames
          ? classNames
          : {
              paper: 'comboBox-paper',
              option: 'comboBox-option',
              input: 'comboBox-input',
            }
      }
      renderOption={
        renderOption
          ? renderOption
          : (option, { selected }) => (
              <div>
                {option.Description} - ({option.CodeId})
              </div>
            )
      }
      renderInput={(params) => (
        <InputField
          params={params}
          name={name}
          id={name}
          disabled={disabled}
          label={label}
          rootClassName={customClassNames && customClassNames.root ? customClassNames.root : ''}
          inputStyles={{ ...inputStyle, ...inputStyles }}
          rootStyles={{ ...rootStyle, ...rootStyles }}
          labelStyles={labelStyles}
          type='text'
          forCombo
          placeholder={placeholder}
        />
      )}
    />
  )
}

export default ComboBox
