import React from 'react'
import { useRef } from 'react'
import onClickOutside from 'react-onclickoutside'
import { Modal, ModalBody, ModalHeader } from '../../Modal/Modal'
import DefaultIcons from './DefaultIcons.js'
import IconList from './IconList'
// import '@coreui/icons/css/free.css'
const showButtonStyles = {
  position: 'absolute' as 'absolute',
  right: 15,
  cursor: 'pointer',
  top: 0,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  userSelect: 'none' as 'none',
}

const iconPickerClickOutsideConfig = {
  handleClickOutside: () => IconPickerInput.handleClickOutside,
}

const IconPickerInput: any = onClickOutside(
  ({
    disabled = false,
    className = '',
    inputStyles = {},
    name = '',
    value = '',
    onChange,
    showButton = true,
    ButtonComponent,
    size = 'sm',
    renderModal,
  }) => {
    const [showIcons, setShowIcons] = React.useState(false)
    const pickerRef = React.useRef()
    const inputRef = useRef<any>()
    const defaultIcons = DefaultIcons

    const toggleIconPicker = (e) => {
      inputRef?.current?.focus()

      // setShowIcons((st) => !st)
    }

    IconPickerInput.handleClickOutside = (e: any) => {
      e.target !== pickerRef?.current && setShowIcons(false)
    }

    const handleKeyDown = (e: any) => {
      //it triggers by pressing the enter key
      if (e.key === 'Tab') {
        setShowIcons(false)
      }
      if (e.key === 'Escape') {
        setShowIcons(false)
      }
    }
    const handleFocus = (e: any) => {
      e && setShowIcons((value) => !value)
    }

    const iconList = (
      <IconList
        name={name}
        onChange={onChange}
        setShowIcons={setShowIcons}
        defaultIcons={defaultIcons}
      />
    )

    return (
      <div
        id='PickerSelection'
        ref={pickerRef}
        onKeyDown={handleKeyDown}
        tabIndex={-1}
        style={{ outline: 'none', width: '100%' }}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', marginTop: '0.1rem' }}
          onClick={() => !showIcons && setShowIcons(true)}
        >
          {value && defaultIcons.includes(value) ? <i className={`${value} icon-box`} /> : null}
          <input
            ref={inputRef}
            disabled={disabled}
            name={name}
            style={{ ...inputStyles, width: '90%' }}
            type='text'
            autoComplete='off'
            value={value}
            className={className}
            onFocus={handleFocus}
            onChange={onChange}
          />
        </div>
        {showButton ? (
          ButtonComponent ? (
            ButtonComponent({ styles: showButtonStyles, togglePicker: toggleIconPicker })
          ) : (
            <small className='input-field-button' onClick={toggleIconPicker}>
              Select
            </small>
          )
        ) : null}
        {size === 'lg' ? (
          renderModal ? (
            renderModal({
              show: showIcons,
              onClose: (close) => setShowIcons(close),
              classNames: { modalContainer: 'custom-icon-picker icon-picker-lg' },
              IconList: iconList,
            })
          ) : (
            <Modal
              show={showIcons}
              className='custom-icon-picker icon-picker-lg'
              onClose={(close) => setShowIcons(close)}
            >
              <ModalHeader>Select an Icon</ModalHeader>
              <ModalBody>{iconList}</ModalBody>
            </Modal>
          )
        ) : (
          <div className={`custom-icon-picker ${showIcons ? 'picker-fade-in' : 'picker-fade-out'}`}>
            {iconList}
          </div>
        )}
      </div>
    )
  },
  iconPickerClickOutsideConfig,
)

export { IconPickerInput }
