// Generated with util/create-component.js
import React from 'react'

import { RadioGroupProps } from './RadioGroup.types'

import './RadioGroup.scss'
import InputField from '../InputField'

const RadioGroup: React.FC<RadioGroupProps> = ({
  name = '',
  label = '',
  options,
  defaultSelected = '',
  color = '#F87DA9',
  onChangeRadio = ({ name, value }) => {
    console.log('NO CHANGE HANDLER DETECTED FOR RADIO', { name, value })
  },
  customClassNames = { root: '', input: '' },
}) => {
  const itemRef = React.useRef<any>()

  React.useEffect(() => {
    if (defaultSelected != '') onChangeRadio({ name, value: defaultSelected })
    itemRef.current.style.setProperty('--main-color', color)
  }, [])

  return (
    <div data-testid='RadioGroup' className='InputField-RadioGroup'>
      <InputField
        rootClassName={customClassNames && customClassNames.root ? customClassNames.root : ''}
        type='radiogroup'
        name={name}
        label={label}
      >
        <div
          ref={itemRef}
          className={customClassNames && customClassNames.input ? customClassNames.input : ''}
        >
          {options.map((option, index) => {
            const checked = option === defaultSelected ? { defaultChecked: true } : {}
            return (
              <div key={`radio-${index}`} style={{ margin: '0.25rem 0' }}>
                <input
                  onChange={() => {
                    onChangeRadio({ name, value: option })
                  }}
                  type='radio'
                  id={`optionBox-${index}`}
                  name='radio-group'
                  {...checked}
                />
                <label htmlFor={`optionBox-${index}`}>{option}</label>
              </div>
            )
          })}
        </div>
      </InputField>
    </div>
  )
}

export default RadioGroup
