import React from 'react'

const EmailInput: React.FC<any> = ({
  disabled = false,
  className = '',
  inputStyles = {},
  name = '',
  value = '',
  onChange,
  showButton = false,
  verifyFunc,
  ButtonComponent,
}) => {
  return (
    <>
      <input
        disabled={disabled}
        name={name}
        style={inputStyles}
        type='email'
        autoComplete='email'
        value={value}
        className={className}
        onChange={onChange}
      />
      {showButton ? (
        ButtonComponent ? (
          ButtonComponent()
        ) : (
          <small className='input-field-button' onClick={verifyFunc}>
            Verify
          </small>
        )
      ) : null}
    </>
  )
}

export { EmailInput }
