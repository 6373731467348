import { useRef, useEffect } from 'react'
export function useHorizontalScroll() {
  const elRef = useRef<HTMLDivElement>()

  useEffect(() => {
    const el = elRef.current

    if (el) {
      const onWheel = (e: WheelEvent) => {
        if (e.deltaY == 0) return

        const hasScrollBarX = elRef.current.clientWidth < elRef.current.scrollWidth
        if (hasScrollBarX) {
          // Prevent Scroll y
          e.preventDefault()
          // scroll X
          el.scrollTo({
            left: el.scrollLeft + e.deltaY / 2.5,
            // behavior: 'smooth'
          })
        }
      }
      el.addEventListener('wheel', onWheel)
      return () => el.removeEventListener('wheel', onWheel)
    }
  }, [])
  return elRef
}
