import React from 'react'
import MaskedInput from 'react-text-mask'

const InputWithMask: React.FC<any> = ({
  disabled = false,
  className = '',
  inputStyles = {},
  name = '',
  value = '',
  onChange,
  showButton = false,
  verifyFunc,
  mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  maskCharacter = '#',
}) => {
  return (
    <>
      <MaskedInput
        disabled={disabled}
        mask={mask}
        className={className}
        style={inputStyles}
        name={name}
        value={value}
        placeholderChar={maskCharacter}
        guide
        showMask
        onChange={onChange}
      />
      {showButton ? (
        <small className='input-field-button' onClick={verifyFunc}>
          Verify
        </small>
      ) : null}
    </>
  )
}

export { InputWithMask }
