import React from 'react'

const UsernameInput: React.FC<any> = ({
  disabled = false,
  className = '',
  inputStyles = {},
  name = '',
  value = '',
  onChange,
}) => {
  return (
    <input
      disabled={disabled}
      name={name}
      style={inputStyles}
      type='username'
      autoComplete='username'
      value={value}
      className={className}
      onChange={onChange}
    />
  )
}

export { UsernameInput }
