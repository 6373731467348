import React from 'react'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import MaskedInput from 'react-text-mask'

const NumberInput: React.FC<any> = ({
  disabled = false,
  className = '',
  inputStyles = {},
  name = '',
  value = '',
  onChange,
  maskOptions,
  type = 'number',
  alignment = 'left',
  maxLength = 0,
  decimals = 0,
  showSeparator = false,
  placeholder = '',
}) => {
  const defaultMaskOptions = {
    prefix: type === 'currency' ? '$' : '',
    suffix: '',
    includeThousandsSeparator: showSeparator,
    thousandsSeparatorSymbol: ',',
    allowDecimal: decimals > 0,
    decimalSymbol: '.',
    decimalLimit: decimals, // how many digits allowed after the decimal
    integerLimit: maxLength, // limit length of integer numbers
    allowNegative: type === 'currency',
    allowLeadingZeroes: type === 'number',
  }

  const numberMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })
  return (
    <>
      <MaskedInput
        disabled={disabled}
        mask={numberMask}
        className={className}
        style={{ textAlign: alignment, ...inputStyles }}
        name={name}
        value={value}
        guide={false}
        type='text'
        showMask={false}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete='off'
      />
    </>
  )
}

export { NumberInput }
