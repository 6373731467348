import axios from 'axios'
import React, { useEffect, useState } from 'react'
import InputField from '../InputField'
import NoteList from './components/NoteList'
import SendIcon from './components/SendIcon'
import { format, isToday, isThisWeek, isThisYear } from 'date-fns'
import { isYesterday } from 'date-fns/esm'
import { Note } from './components/Note'

interface ClassNames {
  input?: any
  root?: any
}
interface PropsNotes {
  name?: string
  placeholder?: string
  classNames?: ClassNames
  renderNotes?: any
  metasId: number
  auth: any
  accessToken: any
}

const SendButton = ({ addNote }) => (
  <button className='Notes-send-button' onClick={addNote}>
    <SendIcon />
  </button>
)
const groupNotesByDate = (notes: any[]) => {
  // this gives an object with dates as keys
  const groupedNotes = notes.reduce((groupedNotes: any, note: any) => {
    let d = new Date(note.ChangedOn)
    const date = d.toString()
    if (!groupedNotes[date]) {
      groupedNotes[date] = []
    }
    groupedNotes[date].push(note)
    return groupedNotes
  }, {})

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groupedNotes).map((date) => {
    return {
      date,
      notes: groupedNotes[date],
    }
  })

  return groupArrays
}
const logoutVerifyErrors = (status) => {
  if(status === 400 || status === 401 || status === 403 || status === 404) {
    localStorage.clear()
    window.location.reload();
  } 
}

const forHumansDate = (date: string) => {
  if (date) {
    const dateParse = new Date(date)
    if (isToday(dateParse)) {
      return `Today ${format(dateParse, "hh:mm aaaaa'm'")}`
    }
    if (isYesterday(dateParse)) {
      return `Yesterday ${format(dateParse, "hh:mm aaaaa'm'")}`
    }

    if (isThisWeek(dateParse)) {
      return format(dateParse, "E hh:mm aaaaa'm'")
    }
    if (isThisYear(dateParse)) {
      return format(dateParse, "E, LLL d hh:mm aaaaa'm'")
    }
    return `${format(dateParse, "E, LLL d, yyy hh:mm aaaaa'm'")}`
  }
  return ''
}
const renderNotesDefault = (notes: any) => {
  return <Note notes={notes} />

  // const groupedNotes = groupNotesByDate(notes)
  // return groupedNotes.map((noteGroup, idx) => (
  //   <React.Fragment key={idx}>
  //     <small
  //       className='d-block text-center mt-2'
  //       style={{ color: 'rgba(241,241,255,0.5)', fontSize: 12 }}
  //     >
  //       {/* {noteGroup.date} */}
  //       {/*  {'Tue Aug 31 2021 21:10:50 GMT-0400 (Bolivia Time)'} */}
  //       {forHumansDate(noteGroup?.date ?? null)}
  //     </small>
  //     {noteGroup?.notes.map((note: any) => (
  //       <NoteList note={note} key={note.Id} />
  //     ))}
  //   </React.Fragment>
  // ))
}

const SmartNotes: React.FC <PropsNotes> = ({
  name = 'Notes',
  placeholder = '',
  metasId,
  renderNotes = renderNotesDefault,
  auth,
  accessToken,
  classNames = { input: '', root: '' }
}) => {
  const inputRef = React.useRef<any>('')
  const [notes, setNotes] = useState([])
  
  const [inputValue, setInputValue] = useState('')
  
  const handleInputChange = ( e ) => {
    setInputValue(e.target.value);
  }

  const addNote = async ( value ) => {
    console.log(value.length);
    // const savedVal = inputRef.current.value
    try{
      await axios.post(
        `https://dispatch.softsmartinc.com/Services/Widget/Notes/Insert`,
        {
          MetasId: metasId,
          Notes: value
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
      fetchNotes(metasId)
      setInputValue('')
      
      }catch(error){
        const status = error.response.status 
        logoutVerifyErrors(status)
      }
    }

  const fetchNotes = async (metasId) => {
    const { data } = await axios.post(
      `https://dispatch.softsmartinc.com/Services/Widget/Notes/List`,
      {
        MetasId: metasId,
        Notes: ""
      },
      {
        headers: { Authorization: `Bearer ${auth}` },
      },
    )
    data.data && setNotes(data.data.NotesList)
  }

  useEffect(() => {
    fetchNotes(metasId)
  }, [])

  
  const renderAllNotes = renderNotes
  ? renderNotes
  : (notes: any) => notes.map(({ Id, Notes }) => <div key={Id}>{Notes}</div>)
  
  const handleKeyPress = (event) => {
    if(event.keyCode === 13 && !event.shiftKey){
      addNote(inputValue)
    }
  }

  return (
    <div className='Notes-root'>
      <div style={{ width: '100%', display: 'inline-block', position: 'relative' }}>
        <textarea 
          value={ inputValue }
          placeholder={placeholder}
          className={classNames.input + ' Notes-notes-area ' + classNames.root}
          name={name}
          id={name}
          onChange={ handleInputChange }
          onKeyDown={handleKeyPress}
        />
        <SendButton addNote={() => addNote(inputValue)} />
      </div>

      {notes.length ? renderAllNotes(notes) : <h5 className='text-center mt-4'>No Notes Found</h5>}
    </div>
  )
}

export default SmartNotes
