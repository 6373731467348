import React from 'react'
import styles from '../../../styles.module.scss'

const TableRow = ({ row, prepareRow, actionClick, idName }: any) => {
  prepareRow(row)
  const handleRowClick = (e: any) => {
    if (e.target && e.target.type === 'button') return
    const id = row.original[idName]
    const actionsColumn = row.cells.find((cell: any) => cell.column.type === 'action')
    if (!actionsColumn) return
    const { actions, rowClickFunc } = actionsColumn.column || []
    const defaultFunc = rowClickFunc
      ? actions.find((ac: any) => ac === rowClickFunc)
      : actions[0] ?? actions[0]
    typeof actionClick === 'function' && defaultFunc && actionClick(defaultFunc, id)
  }
  return (
    <tr
      {...row.getRowProps()}
      onClick={handleRowClick}
      style={actionClick ? { cursor: 'pointer' } : {}}
    >
      {row.cells.map((cell: any) => {
        return (
          <td
            {...cell.getCellProps()}
            className={`${styles[`text-xs-${cell.column.align || 'left'}`]} ${
              styles[`text-${cell.column.align || 'left'}`]
            }`}
            style={{
              maxWidth: 300,
              overflow: 'hidden',
              wordWrap: 'initial',
              textOverflow: 'ellipsis',
            }}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}

export default TableRow
