import React from 'react'
import onClickOutside from 'react-onclickoutside'
import { HexColorPicker } from 'react-colorful'
import { useRef } from 'react'
const showButtonStyles = {
  position: 'absolute' as 'absolute',
  right: 15,
  cursor: 'pointer',
  top: 0,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  userSelect: 'none' as 'none',
}

const colorPickerClickOutsideConfig = {
  handleClickOutside: () => ColorPickerInput.handleClickOutside,
}

const ColorPickerInput: any = React.memo(
  onClickOutside(
    ({
      disabled = false,
      className = '',
      inputStyles = {},
      name = '',
      value = '',
      onChange,
      showButton = true,
      ButtonComponent,
    }) => {
      const [showColors, setShowColors] = React.useState(false)
      const [showCustom, setShowCustom] = React.useState(false)
      const pickerRef = useRef<any>()
      const inputRef = useRef<any>()
      const currentColor = useRef<string>()
      const defaultColors = [
        '#000000',
        '#434343',
        '#666666',
        '#999999',
        '#B7B7B7',
        '#CCCCCC',
        '#D9D9D9',
        '#EFEFEF',
        '#F3F3F3',
        '#FFFFFF',
        '#980000',
        '#FF0000',
        '#FF9900',
        '#FFFF00',
        '#00FF00',
        '#00FFFF',
        '#4A86E8',
        '#0000FF',
        '#9900FF',
        '#FF00FF',
        '#E6B8AF',
        '#F4CCCC',
        '#FCE5CD',
        '#FFF2CC',
        '#D9EAD3',
        '#D0E0E3',
        '#C9DAF8',
        '#CFE2F3',
        '#D9D2E9',
        '#EAD1DC',
        '#DD7E6B',
        '#EA9999',
        '#F9CB9C',
        '#FFE599',
        '#B6D7A8',
        '#A2C4C9',
        '#A4C2F4',
        '#9FC5E8',
        '#B4A7D6',
        '#D5A6BD',
        '#CC4125',
        '#E06666',
        '#F6B26B',
        '#FFD966',
        '#93C47D',
        '#76A5AF',
        '#6D9EEB',
        '#6FA8DC',
        '#8E7CC3',
        '#C27BA0',
        '#A61C00',
        '#CC0000',
        '#E69138',
        '#F1C232',
        '#6AA84F',
        '#45818E',
        '#3C78D8',
        '#3D85C6',
        '#674EA7',
        '#A64D79',
        '#85200C',
        '#990000',
        '#B45F06',
        '#BF9000',
        '#38761D',
        '#134F5C',
        '#1155CC',
        '#0B5394',
        '#351C75',
        '#741B47',
        '#5B0F00',
        '#660000',
        '#783F04',
        '#7F6000',
        '#274E13',
        '#0C343D',
        '#1C4587',
        '#073763',
        '#20124D',
        '#4C1130',
      ]

      const toggleColorPicker = () => {
        inputRef.current.focus()
        // setShowColors((st) => !st)
      }

      ColorPickerInput.handleClickOutside = (e: any) => {
        e.target !== pickerRef?.current && setShowColors(false)
      }

      const handleKeyDown = (e: any) => {
        //it triggers by pressing the enter key
        if (e.key === 'Tab') {
          setShowColors(false)
        }
        if (e.key === 'Escape') {
          setShowColors(false)
        }
      }

      const handleFocus = (e: any) => {
        e && setShowColors((value) => !value)
      }
      const showPicker = () => {
        setShowCustom((show) => !show)
      }

      return (
        <div
          id='PickerSelection'
          ref={pickerRef}
          onKeyDown={handleKeyDown}
          tabIndex={-1}
          style={{ outline: 'none', width: '100%' }}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '0.1rem' }}
            onClick={() => !showColors && setShowColors(true)}
          >
            {value ? <div className='color-box' style={{ backgroundColor: value }}></div> : null}
            <input
              ref={inputRef}
              disabled={disabled}
              name={name}
              style={{ ...inputStyles, width: '90%' }}
              type='text'
              autoComplete='off'
              value={value}
              className={className}
              onFocus={handleFocus}
              onChange={onChange}
            />
          </div>
          {showButton ? (
            ButtonComponent ? (
              ButtonComponent({ styles: showButtonStyles, togglePicker: toggleColorPicker })
            ) : (
              <small className='input-field-button' onClick={toggleColorPicker}>
                Select
              </small>
            )
          ) : null}

          <div
            className={`custom-color-picker ${showColors ? 'picker-fade-in' : 'picker-fade-out'}`}
          >
            {showCustom || (
              <div className='colors'>
                {defaultColors.map((color) => (
                  <div
                    key={color}
                    title={color}
                    onClick={() => {
                      onChange({ target: { name, value: color } })
                      setShowColors(false)
                      // toggleColorPicker();
                    }}
                    className='color-circle'
                    style={{ backgroundColor: color }}
                  ></div>
                ))}
              </div>
            )}

            {showCustom && (
              <div className='colors'>
                <HexColorPicker
                  color={value}
                  onChange={(color: string) => {
                    currentColor.current = color
                  }}
                  onMouseUp={() => {
                    const color = currentColor.current
                    onChange({ target: { name, value: color.toLocaleUpperCase() } })
                  }}
                  onVolumeChange={console.log}
                />
              </div>
            )}
            <small onClick={showPicker} style={{ color: '#1EA7FD', marginLeft: '0.25rem' }}>
              {showCustom ? 'Normal Select' : 'Custom Select'}
            </small>
          </div>
          {/* {value ? <div className='color-box' style={{ backgroundColor: value }}></div> : null} */}
        </div>
      )
    },
    colorPickerClickOutsideConfig,
  ),
)

export { ColorPickerInput }
