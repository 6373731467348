import React from 'react'
import TableHeader from '../TableHeader/TableHeader'
import TableRow from '../TableRow/TableRow'
import styles from '../../../styles.module.scss'

const DataTable = ({
  scrollRef,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  className = '',
  actionClick,
  idName = '',
}: any) => {
  return (
    <div ref={scrollRef} className={`${styles['table-responsive']} ${className}`}>
      <table className={`${styles.table} ${styles['table-bordered']}`} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, i: number) => (
                <TableHeader key={i} column={column} />
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => (
            <TableRow
              key={i}
              row={row}
              prepareRow={prepareRow}
              actionClick={actionClick}
              idName={idName}
            />
          ))}
          {/* <tr>
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <td colSpan={10000}>
                <small>Loading...</small>
              </td>
            ) : (
              <td colSpan={10000}>
                <small>
                  Showing {pageIndex * pageSize + 1} -{' '}
                  {pageIndex * pageSize + page.length} of {totalRecords}
                </small>
              </td>
            )}
          </tr> */}
        </tbody>
      </table>
    </div>
  )
}

export default DataTable
