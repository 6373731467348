import React from 'react'
import ActionButton from '../Components/ActionButton/ActionButton'
import CustomCheckbox from '../Components/CustomCheckbox/CustomCheckbox'
interface FormatCell {
  value?: any
  type?:
    | 'text'
    | 'number'
    | 'currency'
    | 'phone'
    | 'date'
    | 'time'
    | 'datetime'
    | 'boolYesOrNo'
    | 'boolTrueOrFalse'
    | 'boolCheckbox'
    | 'action'
    | 'picture'
    | 'color'
    | 'icon'
  hidePeriod?: any
  actions?: any
  alignment?: any
  actionClick?: any
  row?: any
  idName?: any
  IconComponent?: any
  filesEndpoint?: any
  actionConditions?: any
}
export function formatCell({
  value = '',
  type = 'text',
  hidePeriod = false,
  actions,
  alignment = 'left',
  actionClick,
  row,
  idName = '',
  IconComponent = null,
  filesEndpoint = '',
  actionConditions,
}: FormatCell): any {
  if (value || value === false || value === 0) {
    switch (type) {
      case 'number':
        return String(formatNumber(value))
      case 'currency':
        return String(toCurrency(value))
      case 'phone':
        return String(formatPhoneNumber(value))
      case 'date':
        return String(formatTimeStampToDate(value))
      case 'time':
        return String(formatTimeStampToTime(value, hidePeriod))
      case 'datetime':
        return String(formatTimeStampToDateAndTime(value))
      case 'boolYesOrNo':
        return String(formatBool(value, 'yesOrNo'))
      case 'boolTrueOrFalse':
        return String(formatBool(value, 'trueOrFalse'))
      case 'boolCheckbox':
        return <CustomCheckbox value={value} />
      case 'action':
        return (
          actions.length && (
            <div
              style={{
                display: 'flex',
                justifyContent:
                  alignment === 'left'
                    ? 'flex-start'
                    : alignment === 'right'
                    ? 'flex-end'
                    : 'center',
                alignItems: 'center',
              }}
            >
              {actions.map((action: string, idx: number) => {
                return (
                  <ActionButton
                    key={idx}
                    place={idx === 0 ? 'first' : idx === actions.length - 1 ? 'last' : 'middle'}
                    data={row.original}
                    idName={idName}
                    action={action}
                    actionClick={actionClick}
                    actionLabel={toTitleCase(action)}
                    disabled={
                      actionConditions?.[action]
                        ? actionConditions?.[action]?.(row.original)
                        : false
                    }
                  />
                )
              })}
            </div>
          )
        )
      case 'picture':
        return (
          <div
            style={{
              overflow: 'hidden',
              borderRadius: '50%',
              width: 50,
              height: 50,
              display: 'inline-block',
            }}
          >
            <img
              src={`${filesEndpoint}${value}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
        )
      case 'color':
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                backgroundColor: value,
                display: 'inline-block',
                border: '1px solid rgba(255, 255, 255, 0.4)',
                marginRight: '0.25rem',
                borderRadius: 5,
                width: 14,
                height: 14,
              }}
            ></span>
            {String(value)}
          </div>
        )
      case 'icon':
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconComponent
              name={value}
              style={{
                display: 'inline-block',
                marginRight: '0.25rem',
                width: 14,
                height: 14,
              }}
            />
            {String(value)}
          </div>
        )
      default:
        return String(value)
    }
  } else if (type === 'action' && !value) {
    return (
      actions.length && (
        <div
          style={{
            display: 'flex',
            justifyContent:
              alignment === 'left' ? 'flex-start' : alignment === 'right' ? 'flex-end' : 'center',
            alignItems: 'center',
          }}
        >
          {actions.map((action: string, idx: number) => {
            return (
              <ActionButton
                key={idx}
                place={idx === 0 ? 'first' : idx === actions.length - 1 ? 'last' : 'middle'}
                data={row.original}
                idName={idName}
                action={action}
                actionClick={actionClick}
                actionLabel={toTitleCase(action)}
                disabled={
                  actionConditions?.[action] ? actionConditions?.[action]?.(row.original) : false
                }
              />
            )
          })}
        </div>
      )
    )
  } else {
    return '-'
  }
}

export function toCurrency(numberString: any) {
  let number = parseFloat(numberString)
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })
  return formatter.format(number)
}

export function formatNumber(numberString: any) {
  return parseFloat(numberString).toLocaleString()
}

export function formatPhoneNumber(phoneNumberString: any) {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
  return phoneNumberString.length ? phoneNumberString : ''
}

export function formatTimeStampToDate(timeStampString: any) {
  let unix_timestamp = timeStampString
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  // let date = new Date(unix_timestamp * 1000)
  let date = new Date(unix_timestamp)

  let formattedDate = date.toLocaleDateString('en-US')

  return formattedDate
}

export function formatTimeStampToTime(timeStampString: any, hidePeriod = false) {
  let unix_timestamp = timeStampString
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  // let date = new Date(unix_timestamp * 1000)
  let date = new Date(unix_timestamp)

  let formattedTime = date.toLocaleTimeString('en-US')
  if (hidePeriod) formattedTime = formattedTime.replace(' AM', '').replace(' PM', '')

  return formattedTime
}

export function formatTimeStampToDateAndTime(timeStampString: any) {
  let unix_timestamp = timeStampString
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  // let date = new Date(unix_timestamp * 1000)
  let date = new Date(unix_timestamp)

  let formattedTime = `${date.toLocaleDateString('en-US')} ${date.toLocaleTimeString('en-US')}`

  return formattedTime
}

export function formatBool(value: any, type: any) {
  switch (type) {
    case 'yesOrNo':
      return value ? 'Yes' : 'No'

    case 'trueOrFalse':
      return value ? 'True' : 'False'

    case 'checkbox':
      return value ? 'True' : 'False'

    default:
      break
  }
}

export const toTitleCase = (str: string) => {
  return str
    .split(/\s+|_+/g)
    .map((word) =>
      word.split('').reduce((final, next, idx) => (idx === 1 ? final.toUpperCase() : final) + next),
    )
    .join(' ')
}
