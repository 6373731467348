import React from 'react'
import styles from '../../styles.module.scss'
//
const PanelStat = React.createContext({})

class Panel extends React.Component<any, any> {
  toggleExpand: () => void
  toggleRemove: () => void
  toggleCollapse: () => void
  toggleReload: () => void
  constructor(props: any) {
    super(props)

    this.toggleExpand = () => {
      this.setState((state: any) => ({
        expand: !this.state.expand,
      }))
    }

    this.toggleRemove = () => {
      this.setState((state: any) => ({
        remove: !this.state.remove,
      }))
    }

    this.toggleCollapse = () => {
      this.setState((state: any) => ({
        collapse: !this.state.collapse,
      }))
    }

    this.toggleReload = () => {
      if (this.state.reload !== true) {
        this.setState((state: any) => ({
          reload: true,
        }))

        setTimeout(() => {
          this.setState((state: any) => ({
            reload: false,
          }))
        }, 2000)
      }
    }

    this.state = {
      expand: false,
      collapse: false,
      reload: false,
      remove: false,
      toggleExpand: this.toggleExpand,
      toggleReload: this.toggleReload,
      toggleRemove: this.toggleRemove,
      toggleCollapse: this.toggleCollapse,
    }
  }
  render() {
    return (
      <PanelStat.Provider value={this.state}>
        {!this.state.remove && (
          <div
            className={`${styles.panel} ${
              styles[`panel-${this.props.theme ? this.props.theme : 'inverse'}`]
            } ${this.state.expand ? styles['panel-expand'] : ' '} ${
              this.state.reload ? styles['panel-loading'] : ' '
            } ${this.props.className ? this.props.className : ''}`}
          >
            {this.props.children}
          </div>
        )}
      </PanelStat.Provider>
    )
  }
}

class PanelHeader extends React.Component<any, any> {
  render() {
    return (
      <div className={styles['panel-heading']}>
        <h4 className={styles['panel-title']}>{this.props.children}</h4>
        {!this.props.noButton && (
          <PanelStat.Consumer>
            {({ toggleExpand, toggleRemove, toggleCollapse, toggleReload }: any) => (
              <div className={styles['panel-heading-btn']}>
                <button
                  className={`${styles.btn} ${styles['btn-xs']} ${styles['btn-icon']} ${styles['btn-circle']} ${styles['btn-default']}`}
                  onClick={toggleExpand}
                >
                  <i className={`${styles.fa} ${styles['fa-expand']}`}></i>
                </button>
                &nbsp;&nbsp;
                <button
                  className={`${styles.btn} ${styles['btn-xs']} ${styles['btn-icon']} ${styles['btn-circle']} ${styles['btn-success']}`}
                  onClick={toggleReload}
                >
                  <i className={`${styles.fa} ${styles['fa-redo']}`}></i>
                </button>
                &nbsp;&nbsp;
                <button
                  className={`${styles.btn} ${styles['btn-xs']} ${styles['btn-icon']} ${styles['btn-circle']} ${styles['btn-warning']}`}
                  onClick={toggleCollapse}
                >
                  <i className={`${styles.fa} ${styles['fa-minus']}`}></i>
                </button>
                &nbsp;&nbsp;
                <button
                  className={`${styles.btn} ${styles['btn-xs']} ${styles['btn-icon']} ${styles['btn-circle']} ${styles['btn-danger']}`}
                  onClick={toggleRemove}
                >
                  <i className={`${styles.fa} ${styles['fa-times']}`}></i>
                </button>
              </div>
            )}
          </PanelStat.Consumer>
        )}
      </div>
    )
  }
}

class PanelBody extends React.Component<any, any> {
  render() {
    return (
      <PanelStat.Consumer>
        {({ collapse, reload }: any) => (
          <div
            className={`${styles['panel-body']} ${styles['pt-xs-0']} 
            ${collapse ? styles['d-xs-none'] : ' '} ${this.props.className}`}
            style={{ paddingRight: 0 }}
          >
            {this.props.children}

            {reload && (
              <div className={styles['panel-loader']}>
                <span className={styles['spinner-small']}></span>
              </div>
            )}
          </div>
        )}
      </PanelStat.Consumer>
    )
  }
}

class PanelFooter extends React.Component<any, any> {
  render() {
    return (
      <div className={`${styles['panel-footer']} ${this.props.className}`}>
        {this.props.children}
      </div>
    )
  }
}

export { Panel, PanelHeader, PanelBody, PanelFooter }
