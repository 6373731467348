import React from 'react'
import styles from '../../../styles.module.scss'

interface PropsTableHelear {
  column: any
}
const TableHeader = ({ column }: PropsTableHelear) => {
  const { customWidth, align } = column
  const colProps = column.sortable
    ? { ...column.getHeaderProps(column.getSortByToggleProps()) }
    : { ...column.getHeaderProps() }
  const customStyle = customWidth
    ? { ...colProps.style, width: `${customWidth}%` }
    : { ...colProps.style }
  return (
    <th {...colProps} style={customStyle}>
      <div
        className={`${styles['d-xs-flex']} ${
          align === 'right' ? styles['justify-content-xs-end'] : ''
        }`}
      >
        {align === 'left' ? (
          <React.Fragment>
            <span style={{ userSelect: 'none' }} {...column.getSortByToggleProps()}>
              {column.render('Header')}
            </span>
            <span className={styles['mr-xs-auto']}>
              {column.sortable
                ? column.isSorted &&
                  (column.isSortedDesc ? (
                    /*  <i
                      className={`${styles.fa} ${styles['fa-sort-down']} ${styles['fa-fw']} ${styles['f-s-14']} ${styles['text-blue']}`}
                    ></i> */
                    <>🔻</>
                  ) : (
                    /* <i
                      className={`${styles.fa} ${styles['fa-sort-up']} ${styles['fa-fw']} ${styles['f-s-14']} ${styles['text-blue']}`}
                    ></i> */
                    <>🔺</>
                  ))
                : ''}
            </span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span className={styles['ml-xs-auto']}>
              {column.sortable
                ? column.isSorted &&
                  (column.isSortedDesc ? (
                    /* <i
                      className={`${styles.fa} ${styles['fa-sort-down']} ${styles['fa-fw']} ${styles['f-s-14']} ${styles['text-blue']}`}
                    ></i> */
                    <>⬇</>
                  ) : (
                    /* <i
                      className={`${styles.fa} ${styles['fa-sort-up']} ${styles['fa-fw']} ${styles['f-s-14']} ${styles['text-blue']}`}
                    ></i> */
                    <>⬆</>
                  ))
                : ''}
            </span>
            <span
              style={{ userSelect: 'none' }}
              className={`${align === 'center' ? styles['mr-xs-auto'] : ''}`}
            >
              {column.render('Header')}
            </span>
          </React.Fragment>
        )}
      </div>
    </th>
  )
}

export default TableHeader
