import React from 'react'

const CustomCheckbox = ({ onChange, id, option, isMulti = false }) => {
  return (
    <div className='control control--checkbox' style={{ margin: '0.25rem 0' }}>
      {option.options ? (
        option.options.map(({ name, value, disabled = false }: any, idx: number) => (
          <CheckBox
            key={idx}
            name={name}
            value={value}
            disabled={disabled}
            onChange={(e: any) => {
              onChange({ parentName: option.name, name: name, value: e.target.checked })
            }}
            id={`Option-${option.name}-${idx}`}
            isMulti={isMulti}
            idx={idx}
          />
        ))
      ) : (
        <CheckBox
          name={option.name}
          value={option.value}
          disabled={option.disabled}
          onChange={(e: any) => {
            onChange({ name: option.name, value: e.target.checked })
          }}
          id={`Option-${option.name}-${id}`}
        />
      )}
      <span style={isMulti ? { marginLeft: '1.5rem' } : {}}>{option.label}</span>
    </div>
  )
}

const CheckBox = ({ name, value, disabled, onChange, id, isMulti = false, idx = 0 }) => (
  <>
    <input
      onChange={onChange}
      id={id}
      type='checkbox'
      className='control control--input'
      value={name}
      checked={value}
      disabled={disabled}
    />
    <label
      htmlFor={id}
      style={isMulti ? { marginLeft: idx > 0 ? '1.75rem' : '0.5rem' } : {}}
    ></label>
  </>
)

export default CustomCheckbox
