import React from 'react'
import styles from '../../styles.module.scss'

interface PropsActionsButton {
  data: any
  place: string
  action: string
  actionLabel: string
  style?: string
  actionClick: any
  idName: string
  disabled?: boolean
}
const ActionButton = ({
  data,
  action,
  actionLabel,
  place = '',
  style = '',
  actionClick = () => {},
  idName = '',
  disabled,
}: PropsActionsButton) => {
  style = style !== '' ? style : action === 'delete' ? 'danger' : 'info'
  return (
    <button
      type='button'
      className={`${styles.btn} ${styles[`btn-${style}`]} ${styles['btn-xs']} ${
        place === 'first' ? styles['mr-xs-1'] : place === 'last' ? styles['ml-xs-1'] : ''
      }`}
      onClick={() =>
        typeof actionClick === 'function' && !disabled ? actionClick(action, data[idName]) : null
      }
      disabled={!!disabled}
    >
      {actionLabel}
    </button>
  )
}

export default ActionButton
