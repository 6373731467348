import React from 'react'
import styles from '../../styles.module.scss'
import PageSelect from './PageSelect'

const Pagination = ({
  canPreviousPage,
  gotoPage,
  previousPage,
  pageIndex,
  pageOptions,
  canNextPage,
  nextPage,
  pageCount,
  // setPageSize,
  loading = true,
  page = 1,
  totalRecords = 0,
  pageSize = 25,
  paginationPosition = 'bottom-right',
}: any) => {
  return (
    <div
      className={`${styles['mt-sm-1']} ${styles['justify-content-xs-center']} ${styles['justify-content-sm-start']}`}
      style={{ display: 'flex', flexWrap: 'wrap' }}
    >
      <div className={styles['text-light']}>
        {loading ? (
          // Use our custom loading state to show a loading indicator

          <small>Loading...</small>
        ) : (
          <small style={{ paddingRight: '0.25rem' }}>
            {page.length
              ? `Showing ${pageIndex * pageSize + 1} - ${
                  pageIndex * pageSize + page.length
                } of ${totalRecords}`
              : 'No Records Found'}
          </small>
        )}
      </div>
      {paginationPosition === 'bottom-right' ? (
        <div className={`${styles['ml-sm-auto']}`}>
          <PageSelect
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
            previousPage={previousPage}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            canNextPage={canNextPage}
            nextPage={nextPage}
            pageCount={pageCount}
          />
        </div>
      ) : null}
      {/* <div className={`${styles['ml-xs-3']} ${styles['mr-xs-1']}`}>
        Go to page:
      </div>
      <div className={styles['mx-xs-2']} style={{ maxWidth: 75, minWidth: 50 }}>
        <input
          className={styles['form-control']}
          type='number'
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            gotoPage(page)
          }}
        />
      </div> */}
    </div>
  )
}

export default Pagination
