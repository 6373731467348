import React, { useContext, useEffect } from 'react'
import { useRef } from 'react'
import './Modal.scss'
interface Props {
  show: boolean
  onClose: (value: boolean) => void
  children: React.ReactChild | React.ReactChild[]
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
  className?: string
}
const MyContext = React.createContext<any>(null)
export const Modal: React.FC<Props> = ({
  show,
  onClose,
  children,
  onKeyDown,
  className = '',
}: Props) => {
  const inputRef = useRef<any>()
  useEffect(() => {
    inputRef.current.checked = show
    // console.log('UseEffect', inputRef.current.checked)
  }, [show, inputRef])
  const closeModal = () => {
    inputRef.current.checked = false
    onClose(false)
  }
  return (
    <>
      <MyContext.Provider
        value={{
          closeModal,
        }}
      >
        <div className={`modal-container ${className}`} onKeyDown={onKeyDown}>
          <input
            ref={inputRef}
            id='modal-toggle'
            type='checkbox'
            onChange={(e) => console.log(e)}
          />
          <label className='modal-backdrop' onClick={closeModal}></label>
          <div className='modal-content' style={{ overflowY: 'scroll' }}>
            {children}
          </div>
        </div>
      </MyContext.Provider>
    </>
  )
}
interface ModalHeaderProps {
  children: any
}
export const ModalHeader: React.FC<ModalHeaderProps> = ({ children }: ModalHeaderProps) => {
  // console.log(children, 'HEADER')
  const { closeModal } = useContext(MyContext)
  return (
    <>
      {children}
      <hr />
      <label className='modal-close' onClick={closeModal}>
        &#x2715;
      </label>
    </>
  )
}
interface ModalBodyProps {
  children?: React.ReactNode
  style?: React.CSSProperties
}
export const ModalBody: React.FC<ModalBodyProps> = ({ children, style }: ModalBodyProps) => {
  return <div style={style}>{children}</div>
}
interface ModalFooterProps {
  children?: React.ReactNode
}
export const ModalFooter: React.FC<ModalFooterProps> = ({ children }: ModalFooterProps) => {
  return (
    <>
      <div className='modal-content-btn'>{children}</div>
    </>
  )
}
{
  /*  */
}
